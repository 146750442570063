<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <b-row class="mb-3">
              <b-col md="4">
                <b-form-input v-model="filter" type="search" :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col sm="6" md="12" lg="3" xl="3" class="mb-2">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getUserRegLeadListDateWise()" ></date-range-picker>
                </div>
              </b-col>
              <b-col class="col-12 col-sm-8 col-md-2 col-lg-2 mb-2">
                <!-- Frontend csv download -->
                <VueJsonToCsv :json-data="userRegLeadObjList" csv-title="userDocument" v-if="userRegLeadObjList && userRegLeadObjList.length">
                  <b-btn variant="primary" class="Download_button"> CSV
                  </b-btn>
                </VueJsonToCsv>
                <!-- Frontend csv download -->
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" v-if="userRegLeadObjList && userRegLeadObjList.length > 0">
                <b-table hover responsive :items="userRegLeadObjList" :fields="columns" :no-border-collapse="true"
                  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                  :current-page="currentPage" :per-page="perPage">
                  <template v-slot:cell(created_on)="data">
                    {{ getFormattedDateTime(data.item.created_on) }}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm"
                      @click="userRegLeadEdit('MODAL', data.item)" v-if="!data.item.editable"><i
                        class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" @click="showUserRegLeadDeleteDialog(data.item)" size="sm"><i
                        class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal v-model="showModelUserRegLeadEdit" scrollable :title="cvEditModalHeader" size="xl">
      <UserRegLeadEdit :propOpenedInModal="true" :propUserRegLeadObj="userRegLeadEditObj"
        @emitCloseUserRegLeadEditModal="closeUserRegLeadEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="userRegLeadEdit('FULLPAGE')">
          {{ cvbtnModalExpand }}
        </b-button>
        <b-button size="sm" @click="closeUserRegLeadEditModal()">
          {{ cvbtnModalCancel }}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelUserRegLeadDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{ cvDelMessage }}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelUserRegLeadDelete = false">
          {{ cvbtnModalCancel }}
        </b-button>
        <b-button size="sm" variant="primary" @click="userRegLeadDelete()">
          {{ cvbtnModalDelOk }}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { UserRegLeads } from "../../../FackApi/api/UserRegLead"
import UserRegLeadEdit from "./UserRegLeadEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "UserRegLeadList",
  components: {
    UserRegLeadEdit
  },
  data () {
    return {
      apiName: "user_reg_lead_list",
      cvCardTitle: "Contact Us - Others",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit UserRegLead",
      cvAddModalHeader: "Add UserRegLead",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "UserRegLead List Response",
      showModelUserRegLeadAdd: false,
      showModelUserRegLeadEdit: false,
      showModelUserRegLeadDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left w-50px align-text-top text-nowrap ", sortable: true },
        { label: "Name", key: "usrl_name", class: "text-left w-100px align-text-top text-nowrap", sortable: true },
        { label: "Email", key: "usrl_email", class: "text-left w-100px align-text-top text-nowrap", sortable: true },
        { label: "Type", key: "usrl_type", class: "text-left w-100px align-text-top text-nowrap", sortable: true },
        { label: "Organisation", key: "usrl_org_name", class: "text-left w-100px align-text-top text-nowrap", sortable: true },
        { label: "contact no", key: "usrl_contact_no", class: "text-left w-100px align-text-top text-nowrap", sortable: true },
        { label: "Desc", key: "usrl_about_myself", class: "text-left w-150px align-text-top text-nowrap", sortable: true },
        { label: "City", key: "usrl_city", class: "text-left w-100px align-text-top text-nowrap", sortable: true },
        { label: "State", key: "usrl_state", class: "text-left w-100px align-text-top text-nowrap", sortable: true },
        { label: "Country", key: "usrl_country", class: "text-left w-100px align-text-top text-nowrap", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left w-100px align-text-top text-nowrap", sortable: true },
        { label: "Action ", key: "action", class: "text-left w-100px align-text-top text-nowrap", sortable: false }
      ],
      userRegLeadObjList: null,
      userRegLeadEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 1)), // From Last Month
          endDate: new Date(new Date().setDate(new Date().getDate() + 30))
        }
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    this.userRegLeadList()
    await this.getDateFilter()
  },
  methods: {
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * Calling Function as per whereFilter
    */
    async getUserRegLeadListDateWise () {
      this.userRegLeadList()
      await this.setDateFilter()
    },
    /**
      * Setting Date Range in the LS
    */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * userRegLeadList
     */
    async userRegLeadList () {
      try {
        let userRegLeadListResp = await UserRegLeads.userRegLeadList(this, this.whereFilter)
        if (userRegLeadListResp.resp_status) {
          this.userRegLeadObjList = userRegLeadListResp.resp_data.data
          this.totalRows = userRegLeadListResp.resp_data.count
        }
        else {
          this.toastMsg = userRegLeadListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at userRegLeadList() and Exception:", err.message)
      }
    },
    /**
     * userRegLeadAdd
     */
    userRegLeadAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("user_reg_lead_add")
        }
        else {
          this.showModelUserRegLeadAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at userRegLeadAdd() and Exception:", err.message)
      }
    },
    /**
     * userRegLeadEdit
     */
    userRegLeadEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/user_reg_lead_edit/" + this.userRegLeadEditObj.usrl_id)
        }
        else {
          this.userRegLeadEditObj = item
          this.showModelUserRegLeadEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at userRegLeadEdit() and Exception:", err.message)
      }
    },
    /**
     * showUserRegLeadDeleteDialog
     */
    showUserRegLeadDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelUserRegLeadDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showUserRegLeadDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * userRegLeadDelete
     */
    async userRegLeadDelete () {
      try {
        let userRegLeadDelResp = await UserRegLeads.userRegLeadDelete(this, this.delObj.usrl_id)
        await ApiResponse.responseMessageDisplay(this, userRegLeadDelResp)

        if (userRegLeadDelResp && !userRegLeadDelResp) {
          this.showModelUserRegLeadDelete = false
          return false
        }

        let index = this.userRegLeadObjList.indexOf(this.delObj)
        this.userRegLeadObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelUserRegLeadDelete = false
      }
      catch (err) {
        console.error("Exception occurred at userRegLeadDelete() and Exception:", err.message)
      }
    },
    /**
     * closeUserRegLeadAddModal
     */
    closeUserRegLeadAddModal (userRegLeadAddData) {
      try {
        if (userRegLeadAddData) {
          if (this.userRegLeadObjList && this.userRegLeadObjList.length >= 1) {
            let usrlObjLength = this.userRegLeadObjList.length
            let lastId = this.userRegLeadObjList[usrlObjLength - 1]["id"]
            userRegLeadAddData.id = lastId + 1
          }
          else {
            this.userRegLeadObjList = []
            userRegLeadAddData.id = 11111
          }

          userRegLeadAddData.created_on = moment()
          this.userRegLeadObjList.unshift(userRegLeadAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelUserRegLeadAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeuserRegLeadAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeUserRegLeadEditModal
     */
    closeUserRegLeadEditModal () {
      try {
        this.showModelUserRegLeadEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeUserRegLeadEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
